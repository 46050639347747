body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

.loader {
  --path: #2f3545;
  --dot: #5628ee;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}

.loader:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect, .loader svg polygon, .loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader.triangle {
  width: 48px;
}

.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}

 


.text-gradient {
  background: linear-gradient(269.34deg, #FC8A3D -0.22%, #EB314D 49.89%, #DA138E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bgg-gradient {
  background: linear-gradient(269.34deg, #FC8A3D -0.22%, #EB314D 49.89%, #DA138E 100%);
}




.table-view-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 70%);
}

.background-gradient {
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.07) 52.22%, rgba(0, 0, 0, 0) 75.56%);
}

.background-white-gradient {
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.07) 52.22%, rgba(255, 255, 255, 0) 75.56%);
}

.sections__background-white-gradient {
  background: linear-gradient(0deg, #ffffff 30%, rgba(255, 255, 255, 0.07) 100%, rgba(255, 255, 255, 0) 100%);
}

.Results-wrapper {
  max-width: 100vw;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    rgb(0, 0, 0) 20%,
    rgb(0, 0, 0) 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, rgb(0, 0, 0) 20%, rgb(0, 0, 0) 80%, transparent);
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}

.mask-right {
  right: 0;
  background: black;
  opacity : 0.5
}

.mask-left {
  left: 0;
  background: black;
  opacity : 0.5
}


.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  pointer-events: none;
  z-index: 1000;
  transition: transform 0.1s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.custom-cursor-white {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 14px;
  font-weight: bold;
  pointer-events: none;
  z-index: 1000;
  transition: transform 0.1s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.scrolling-text-container {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%;
  box-sizing: content-box;
  animation: scroll-text linear infinite;
}

@keyframes scroll-text {
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-100%);
  }
}

.looper {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.looper__innerList {
  display: flex;
  width: fit-content;
  animation-name: scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.looper__innerList[data-animate="false"] {
  animation-play-state: paused;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper__listInstance {
  display: flex;
}

.scroller {
  max-width: 100vw
}

.scrollerHero {
  max-width : 500px;
  background-color : yellow;
}

.scrollerAnnouncement {
  max-width : 100vw;
  background-color : #fbe524;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.scroller__inner__announcement {
  padding-block: 0.3rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
 /* -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent); */
}

.scrollerHero[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 5%,
    white 95%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scrollerSection[data-animated="true"] {
  overflow: hidden;
  mask : none;
}

.scrollerAnnouncement[data-animated="true"] {
  overflow: hidden;
  mask : none;
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 90s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  border-radius: 0.5rem;
  font-size: 1.2rem;
}

.tag-list label {
  padding : 2px 5px
}

.embed-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.instagram-media {
  width: 100% !important;
  height: 100% !important;
}

/* Custom styles to hide profile information */
.instagram-media .instgrm {
  display: none;
}

.instagram-media .embed-container a {
  display: none !important;
}

.instagram-media .embed-container p {
  display: none !important;
}

.instagram-media .embed-container .embed-container div {
  display: none !important;
}

/* styles.css or any global css file */
.link-underline {
  position: relative;
}

.link-underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.link-underline.active::after,
.link-underline:hover::after {
  transform: scaleX(1);
}

/* styles.css */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 9999;
  transition: transform 0.3s ease-in-out;
}

.header-hidden {
  transform: translateY(-100%);
}

/* Add this CSS to your styles */

.loaderHero {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cust__container {
  width : 100vw;
  padding-left : 100px;
  padding-right : 100px;
  overflow-x: hidden;
}

.cust__work-container {
  width : 100vw;
  padding-left : 100px;
  padding-right : 100px;
}

@media (max-width : 1024px ){
   .cust__container {
    padding-left: 15px;
    padding-right: 15px;
   }

   .cust__work-container {
    padding-left: 15px;
    padding-right: 15px;
   }

}


.thing:hover{
  animation : thing 5s infinite;
}

@keyframes thing {
  0% { transform : rotate(0deg)}
  3% { transform : rotate(-3deg)}
  4% { transform : rotate(3deg)}
  5% { transform : rotate(0deg)}
  100% { transform : rotate(0deg)}

}


.pulse {
  width: 22.4px;
  height: 22.4px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 0 0 0 rgba(255,255,255,0.5);
  animation: pulse-c6zlskmd 1s infinite;
}

@keyframes pulse-c6zlskmd {
  100% {
     box-shadow: 0 0 0 33.6px #0000;
  }
}


.text-scale {
  position: relative;
  transform: scale(1);
  transition: transform ease-in 250ms;
   
}

.text-scale:hover {
  transform: scale(1.1);
}


@tailwind base;
@tailwind components;
@tailwind utilities;