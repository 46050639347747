.mobile__break {
    display : none ;
}

.desk__space {
    display : inline;
}

.swiper-button-prev {
    left : -6px;
}

.swiper-button-next{
    right : -6px
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 2em;
    color : white;
}

@media (max-width : 428px) {

    .mobile__break {
        display: block;
    }

    .desk__space {
        display : none;
    }

    .swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 1.4em;
}

.swiper-button-prev {
    left : -8px;
}

.swiper-button-next{
    right : -8px
}

}
