.sections__headline-wrapper {
    line-height: 0.9em;
}

.section__item {
    position: relative;
}

.section__item::before {
    content : attr(data-count);
    position : absolute;
    top : -10px;
    right : -20px;
    font-size: .6em;
    padding : 4px 5px;
    border-radius: 50%;
    background-color: #e3e4eb;
}