.datatable__total {
    position : relative;
}

.datatable__total::before {
    content : '₹65000/-';
    font-size: 0.4em;
    
    color : #949494;
    text-decoration: line-through;
}

table {
    will-change: transform;
  }
  